<template>
    <Loading :isLoading="isLoading" />
    <html>
      <body class="" style="background: #FFF">
        <HeaderMobil :localNavigation="false" v-on:go-to-back="changeGoToBack" />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="bg-container col-span-6">
                <div v-if="!continueQuote" class="w-full md:w-4/5 mx-auto h-auto py-10 md:py-16 grid grid-cols-1 md:grid-cols-2">
                    <div class="px-5">
                        <p class="text-2xl md:text-3xl font-bold mb-5 md:mb-10 RobotoBold">Resumen</p>
                        <div class="border shadow rounded border-gray-100 px-5 py-4">
                            <p class="text-lg font-light mb-2 RobotoBold">Datos Personales</p>  
                            
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">R.F.C.:</p> 
                                <p class="text-md font-light mb-2 RobotoLight">
                                    {{ policyIssuanceClass.contractingParty.rfc }}
                                </p>
                            </div>
                            
                            <p class="text-lg font-light mb-0 RobotoBold">Dirección</p>  
                            <div class="grid grid-cols-1 mb-4">
                                <p class="text-sm font-light RobotoLight">{{ `${policyIssuanceClass.contractingParty.street} #${policyIssuanceClass.contractingParty.number_ext}` }}</p>
                            </div>
                            <div class="grid grid-cols-2">
                                <div class="mb-2">
                                    <p class="text-sm font-light RobotoLight">Colonia:</p> 
                                    <p class="text-md font-light RobotoLight">{{ policyIssuanceClass.contractingParty.suburb }}</p>
                                </div>
                                <div class="mb-2">
                                    <p class="text-sm font-light RobotoLight">C.P.:</p> 
                                    <p class="text-md font-light RobotoLight">{{ policyIssuanceClass.contractingParty.postal_code }}</p>
                                </div>
                            </div>
                            <div class="grid grid-cols-1">
                                <div class="mb-2">
                                    <p class="text-md font-light RobotoLight" >{{ contractingLocation }}</p>
                                </div>
                            </div>
                            
                            <div class="mb-5 mt-5"><p class="text-lg font-light mb-5 RobotoBold">Datos Laborales del Solicitante</p></div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Empresa donde trabaja:</p> 
                                <p class="text-md font-light RobotoLight">{{ companyName }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Ocupación:</p> 
                                <p class="text-md font-light RobotoLight">{{ occupation }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Ingresos Anuales:</p> 
                                <p class="text-md font-light RobotoLight">{{ $filters.formatCurrency(annualIncome) }}</p>
                            </div>

                            <div class="mb-5 mt-5"><p class="text-lg font-light mb-5 RobotoBold">Cobranza</p></div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Método de Pago:</p> 
                                <p class="text-md font-light RobotoLight">{{ $filters.formatCamelize(policyIssuanceClass.coverageInformation.payment_method) }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Frecuencia de Pago:</p> 
                                <p class="text-md font-light RobotoLight">{{ $filters.formatCamelize(policyIssuanceClass.coverageInformation.payment_frequency) }}</p>
                            </div>

                            <div class="mb-5 mt-5"><p class="text-lg font-light mb-5 RobotoBold">Beneficiarios</p></div>
                            <div class="mb-2 grid grid-rows-1 mb-2" v-for="(beneficiary, index) in policyIssuanceClass.beneficiaries" :key="index">
                                <p class="text-xl font-light RobotoLight">{{ `${beneficiary.name} ${beneficiary.paternal_name} ${beneficiary.maternal_name}`}}</p> 
                                <div class="grid grid-cols-2">
                                    <p class="text-md font-light RobotoLight capitalize">{{ beneficiary.relationship }} | {{ getYears(beneficiary.date_of_birth) }} años</p>
                                    <p class="flex text-md font-light RobotoLight capitalize justify-center">{{ beneficiary.percentage }}%</p>
                                </div>
                            </div>

                            <div class="mb-5 mt-5"><p class="text-lg font-light mb-5 RobotoBold">Documentos</p></div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">INE/IFE:</p> 
                                <p class="text-md font-light RobotoRegular" v-for="(document, index) in documentListIne" :key="index">{{ document.name }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Comprobante de domicilio:</p> 
                                <p class="text-md font-light RobotoRegular" v-for="(document, index) in documentListProofOfAddress" :key="index">{{ document.name }}</p>
                            </div>

                        </div>
                        <input type="button" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-5 w-full h-14 md:h-14  rounded text-center font-medium text-xl" @click="confirm" value="Finalizar">
                    </div>
                    <div class="px-10 py-16 hidden md:block">
                        <img :src="require('@/assets/Cuenta/vidaSimple/finished.svg')" alt="FONCABSA" class="mx-auto">
                    </div>
                </div>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import { onMounted, onBeforeMount, ref, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue";
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';;
import MenuLateral from '@/components/LateralMenu.vue';;
import Footer from '@/components/Footer.vue';
import { getTokenDecoden, getUserInfo } from '@/helpers/tokenauth.js';

import moment from "moment";
import Swal from "sweetalert2";
import Quote from "@/classes/Quote";
import PolicyIssuance from "@/classes/PolicyIssuance";

import Loading from "@/components/Loading/VueLoading.vue";
import { post } from 'axios';

export default {
    setup(){
        const token = ref(null);
        const router = useRouter();
        const category = ref('');
        let counterGoBack = ref(0);
        let continueQuote = ref(false);
        let detail = ref({
            personal_data:{
                full_name: "",
                age: 0,
                gender: "",
                height: 0,
                weight: 0
            },
            contact_data: {
                telephone: "",
                email: ""
            },
            diseases: [],
            sports: [],
            occupation: [],
            surgery: [],
            hobbies: [],
            coverages: [],
            assured_amount: 0,
            smoke: ''
        });
        let companyName = ref('');
        let occupation = ref('');
        let annualIncome = ref(null);

        const quoteClass = ref(null);
        const policyIssuanceClass = ref(null)
        const isLoading = ref(true)

        const documentListIne = ref({});
        const documentListProofOfAddress = ref({});
        const tokenAuthorization = ref(null);
        const contractingLocation = ref('');
        

        let app = getCurrentInstance();

        onBeforeMount( async () => {
            quoteClass.value = new Quote()
            policyIssuanceClass.value = new PolicyIssuance();

            if(storageExist('quote')){
                app = app.appContext.config.globalProperties;
                const issuanceExist = storageExist('issuance-data');
                const quoteExist = storageExist('quote-data');
                const documentsIneExist = storageExist('document_ine');
                const documentProofOfAddressExist = storageExist('document_proof_of_address');
                const issuanceApplicantAddress = storageExist('issuance-applicant-address');

                if(issuanceExist && quoteExist && documentsIneExist && documentProofOfAddressExist || issuanceApplicantAddress){
                    await loadInfo();
                    getTokenQuotation();
                    return;
                }
            } 
           
            if(!storageExist('quote')) {
                router.push({path: '/cuenta/principal'});
            }
        });

        onMounted(async()=>{
            isLoading.value = false;
            tokenAuthorization.value = await getTokenAuthorization();
        });

        const getTokenAuthorization = async () => {
            return await quoteClass.value.getToken().then(resp => resp.data.response.token_auth)
        }

        const getYears = (birthday) => {
            birthday = moment(birthday);
            let today = moment();
            let age = today.diff(birthday,"years");
            return age;
        }

        const loadInfo = async() => {
            
            const userInfo = getTokenDecoden();
            /* const userData = getUserInfo(); */
            const token = getSessionStorage('login');
            const userId = userInfo.obj.person.fonsnte_user_id;
            const issuanceData = getStorage('issuance-data');
            const quoteData = getStorage('quote-data');
            const documentsIne = getStorage('document_ine');
            const applicantAddress = getStorage('issuance-applicant-address');

            const documentProofOfAddress = getStorage('document_proof_of_address');
            let personalInfo = userInfo.obj.person;
            let userInfoQuote = await getUserInformation(token, userId);
            
            /* console.log("personalInfo: ", personalInfo);
            console.log("userInfoQuote: ", userInfoQuote); */
            if(userInfoQuote.has_errors){
                return;
            }

            /* console.log('applicantAddress:', applicantAddress)
            console.log("IissuanceData: ", issuanceData);
            console.log("quoteData: ", quoteData);
            console.log("userData: ", userData); */
             console.log("applicant home:", policyIssuanceClass.value.applicantInformation);

            
            const additionalApplicant = quoteData.contracting_data.contractor_other_than_the_applicant;
            contractingLocation.value = app.$filters.formatCamelize(`${ personalInfo.city }, ${ personalInfo.municipality }, ${ personalInfo.state } ${  personalInfo.country }`);

            policyIssuanceClass.value.application_date = moment().format('YYYY-MM-DD');
            policyIssuanceClass.value.location = contractingLocation;//TODO Direccion de contratante pero debe ser Geolocation sino es posible tomar se tomaria desde el contratante
            policyIssuanceClass.value.start_date = moment().format('YYYY-MM-DD');

            let yearsRemaining = 99 - getYears(personalInfo.birthday);
            
            policyIssuanceClass.value.end_date = moment().add(yearsRemaining, 'years').format('YYYY-MM-DD');
            policyIssuanceClass.value.is_renewal = false;
            policyIssuanceClass.value.retainer_number = 11;
            policyIssuanceClass.value.type_of_delivery = "correo asegurado";

            // console.log("location: ", contractingLocation);
            policyIssuanceClass.value.contractingParty = {
                "name": app.$filters.formatCamelize(personalInfo.name),
                "middle_name": app.$filters.formatCamelize(personalInfo.middle_name),
                "paternal_name": app.$filters.formatCamelize(personalInfo.paternal_name),
                "maternal_name": app.$filters.formatCamelize(personalInfo.maternal_name),
                "rfc": personalInfo.rfc.toString().toUpperCase(),
                "curp": personalInfo.curp.toString().toUpperCase(),
                "street": app.$filters.formatCamelize(personalInfo.street),
                "number_ext": personalInfo.ext_number,
                "number_int": personalInfo.int_number,
                "suburb": app.$filters.formatCamelize(personalInfo.suburb),
                "city": app.$filters.formatCamelize(personalInfo.city),
                "state": app.$filters.formatCamelize(personalInfo.state),
                "municipality": app.$filters.formatCamelize(personalInfo.municipality),
                "postal_code": personalInfo.postal_code,
                "cellphone": userInfoQuote.simple_life_info.contact_details.cellphone,
                "phone": userInfoQuote.simple_life_info.contact_details.phone,
                "email": userInfo.obj.email,
                "annual_income": userInfoQuote.simple_life_info.salary_info.monthly_income * 12,
                "occupation": app.$filters.formatCamelize(userInfoQuote.simple_life_info.work_info.occupation),
                "company_name": app.$filters.formatCamelize(userInfoQuote.simple_life_info.workplace.name),
                "relationship_with_applicant": (additionalApplicant == 'si')? quoteData.contracting_data.relationship_to_applicant:""//TODO Agregar laboral o familiar si un adicional
            }
            
            //TODO reivsar si es necesario condiciones de solicitante o contratante
            
             console.log("applicant value:", policyIssuanceClass.value.applicantInformation);

            if (additionalApplicant == 'no') {
                policyIssuanceClass.value.applicantInformation = {
                    "name": app.$filters.formatCamelize(personalInfo.name),
                    "middle_name": app.$filters.formatCamelize(personalInfo.middle_name),
                    "paternal_name": app.$filters.formatCamelize(personalInfo.paternal_name),
                    "maternal_name": app.$filters.formatCamelize(personalInfo.maternal_name),
                    "rfc": (additionalApplicant == "no")? personalInfo.rfc.toString().toUpperCase(): quoteData.contracting_data.input_rfc.toString().toUpperCase(),
                    "curp": personalInfo.curp.toString().toUpperCase(),
                    "street": app.$filters.formatCamelize(personalInfo.street),
                    "number_ext": personalInfo.ext_number,
                    "number_int": personalInfo.int_number,
                    "suburb": app.$filters.formatCamelize(personalInfo.suburb),
                    "city": app.$filters.formatCamelize(personalInfo.city),
                    "state": app.$filters.formatCamelize(personalInfo.state),
                    "municipality": app.$filters.formatCamelize(personalInfo.municipality),
                    "postal_code": personalInfo.postal_code,
                    "cellphone": userInfoQuote.simple_life_info.contact_details.cellphone,
                    "phone": userInfoQuote.simple_life_info.contact_details.phone,
                    "place_of_birth": app.$filters.formatCamelize(personalInfo.suburb),
                    "date_of_birth": personalInfo.birthday,
                    "age": getYears(personalInfo.birthday),
                    "gender": personalInfo.gender.toLowerCase().replace('(a)', ''),//TODO mandar en minusculas y sin (A) o (O)
                    "marital_status": personalInfo.marital_status.toLowerCase().replace('(a)', ''),//TODO mandar en minusculas y sin (A) o (O)
                    "annual_income": userInfoQuote.simple_life_info.salary_info.monthly_income * 12,
                    "email": userInfo.obj.email,
                    "occupation": app.$filters.formatCamelize(userInfoQuote.simple_life_info.work_info.occupation),
                    "description_occupation":"",
                    "company_name": app.$filters.formatCamelize(userInfoQuote.simple_life_info.workplace.name),
                    "workplace": "",
                    "type_of_activity": "",
                    "renewable_beneficiaries": true
                }
                
            } else {
                policyIssuanceClass.value.applicantInformation = {
                    "name": app.$filters.formatCamelize(quoteData.contracting_data.input_nombre_contracting_data),
                    "middle_name": "",
                    "paternal_name": app.$filters.formatCamelize(quoteData.contracting_data.input_paternal_name_contracting_data),
                    "maternal_name": app.$filters.formatCamelize(quoteData.contracting_data.input_maternal_name_contracting_data),
                    "rfc": quoteData.contracting_data.input_rfc.toString().toUpperCase(),
                    "curp": quoteData.contracting_data.curp_contracting_data.toString().toUpperCase(),
                    "street": app.$filters.formatCamelize(issuanceData.applicant_address.street_applicant_address),
                    "number_ext": issuanceData.applicant_address.no_ext_applicant_address,
                    "number_int": issuanceData.applicant_address.no_int_applicant_address,
                    "suburb": issuanceData.applicant_address.street_applicant_address,
                    "city": applicantAddress.city == '' ?  'N/A' : app.$filters.formatCamelize(applicantAddress.city),
                    "state": app.$filters.formatCamelize(applicantAddress.state),
                    "municipality": app.$filters.formatCamelize(applicantAddress.municipality),
                    "postal_code": issuanceData.applicant_address.postal_code_applicant_address,
                    "cellphone": quoteData.contractor_contact.cellphone_contractor_contact,
                    "phone": quoteData.contractor_contact.phone_contractor_contact,
                    "place_of_birth": (applicantAddress.municipality == 'Cajeme') ? 'Ciudad Obregón' :  app.$filters.formatCamelize(quoteData.contracting_data.birthplace_contracting_data),
                    "date_of_birth": quoteData.contracting_data.birthdate_contracting_data,
                    "age": getYears(formatDate(quoteData.contracting_data.birthdate_contracting_data)),
                    "gender": (quoteData.contracting_data.gender_contracting_data == 'hombre')? 'masculino': 'femenino',
                    "marital_status": quoteData.contracting_data.marital_status_contracting_data.toLowerCase().replace('(a)', ''),
                    "annual_income": issuanceData.applicants_employment_data.annual_income_applicants_employment_data,
                    "email": quoteData.contractor_contact.email_contractor_contact,
                    "occupation": app.$filters.formatCamelize(issuanceData.applicants_employment_data.occupation_applicants_employment_data),
                    "description_occupation": "",
                    "company_name": app.$filters.formatCamelize(issuanceData.applicants_employment_data.company_where_you_work_applicants_employment_data),
                    "workplace": "",
                    "type_of_activity": "",
                    "renewable_beneficiaries": false
                }

            }

            occupation.value = additionalApplicant == 'no' ? app.$filters.formatCamelize(userInfoQuote.simple_life_info.work_info.occupation) : app.$filters.formatCamelize(issuanceData.applicants_employment_data.occupation_applicants_employment_data);
            companyName.value =  additionalApplicant == 'no' ? app.$filters.formatCamelize(userInfoQuote.simple_life_info.workplace.name) : app.$filters.formatCamelize(issuanceData.applicants_employment_data.company_where_you_work_applicants_employment_data);
            annualIncome.value = additionalApplicant == 'no' ? userInfoQuote.simple_life_info.salary_info.monthly_income * 12 : issuanceData.applicants_employment_data.annual_income_applicants_employment_data;

             console.log("applicant:", policyIssuanceClass.value.applicantInformation);

            let coveragesInfo = quoteData.coverages.added_benefit.map(coverage => coverage.option)
            let paymentMethod = issuanceData.collection.payment_method_collection;
            switch (paymentMethod) {
                case 'nomina':
                    paymentMethod = "descuento por nomina";
                    break;
                case 'domiciliación':
                    paymentMethod = "tarjeta de débito";
                    break;
                default:
                    ""
                    break;
            }

            policyIssuanceClass.value.coverageInformation = {
                "vitalicio": (coveragesInfo.includes('vitalicio'))? true: false,
                "vitalicio_assured_amount": (quoteData.coverage)? quoteData.coverages.amount_insured : 0,
                "vitalicio_national_currency": true,
                "vitalicio_term_reached_age": true,
                "vitalicio_term_years_of_insurance": 0,
                "vitalicio_term_years_of_payment": 0,
                "dotal": (coveragesInfo.includes('dotal'))? true: false,
                "dotal_number_months": 0,
                "dotal_monthly_price": 0,
                "bit": (coveragesInfo.includes('bit'))? true: false,
                "bit_disability_and_permanent_payments": (coveragesInfo.includes('bit'))? true: false,
                "bpai": (coveragesInfo.includes('bpai'))? true: false,
                "bpai_assured_amount": (quoteData.coverages && coveragesInfo.includes('bpai'))? quoteData.coverages.amount_insured : 0,
                "ma": (coveragesInfo.includes('ma'))? true: false,
                "ma_assured_amount": (quoteData.coverages && coveragesInfo.includes('ma'))? quoteData.coverages.amount_insured : 0,
                "di": (coveragesInfo.includes('di'))? true: false,
                "di_assured_amount": (quoteData.coverages && coveragesInfo.includes('di'))? quoteData.coverages.amount_insured : 0,
                "ti": (coveragesInfo.includes('ti'))? true: false,
                "ti_assured_amount": (quoteData.coverages && coveragesInfo.includes('ti'))? quoteData.coverages.amount_insured : 0,
                "eg": (coveragesInfo.includes('eg'))? true: false,
                "eg_assured_amount": (quoteData.coverages && coveragesInfo.includes('eg'))? quoteData.coverages.amount_insured : 0,
                "cpf": (coveragesInfo.includes('cpf'))? true: false,
                "cpf_assured_amount": (quoteData.coverage)? quoteData.coverages.amount_insured : 0,
                "provisional": false,
                "provisional_coverage_name": "Prueba",
                "provisional_agent_key": "12434",
                "provisional_folio_number": "00000",
                "provisional_date": "2020-05-22",
                "provisional_assured_amount": 100000,
                "provisional_names": "jorge daniel",
                "provisional_paternal_name": "olguin",
                "provisional_maternal_name": "izguirre",
                "payment_frequency": (issuanceData.collection)? issuanceData.collection.frecuency_collection: '',
                "payment_method": paymentMethod,
                "retainer": "cabsa"
            }

            let smoke = quoteData.smoke;
            let alcohol = quoteData.alcohol;
            let surgeries = quoteData.operations;
            let _personalInfo = (additionalApplicant == 'si')? quoteData.contracting_data: quoteData.personal_info;

            policyIssuanceClass.value.healthQuestions = {
                "is_smoker": (smoke.smoke_tobacco == 'no')? false: true,
                "type_smoker": smoke.tobacco_type_list,
                "amount_smoker": smoke.tobacco_types,
                "frequency_smoker": smoke.how_often_you_smoke,
                "date_since_you_quit_smoking": formatDate(smoke.stop_date_smoke),
                "use_drugs": (smoke.consume_narcotics == 'no')? false: true,
                "using_drugs_since": formatDate(smoke.start_date_consume_nacotics),
                "drug_type": smoke.list_of_narcotics_types,
                "drug_frequency": smoke.drug_use_frequency,
                "last_time_using_drugs": formatDate(smoke.start_date_consume_nacotics),
                "received_treatment_related_to_drug_use": (smoke.has_recived_treatment == 'si')? true: false,
                "is_alcoholic": (alcohol.consume_alcoholic == 'no')? false: true,
                "amount_of_alcoholic_beverage": alcohol.amount_alcohol,
                "frequency_of_alcoholic_beverage": alcohol.frequency_of_drinking,
                "date_since_stopped_drinking_alcohol": formatDate(alcohol.end_date_consume_alcohol),
                "p1_height": _personalInfo.height,
                "p2_weight": _personalInfo.weight,
                "p8_had_surgery": (surgeries.have_you_had_an_operation == 'si')? true: false,
                "p12_pregnancy": false,
                "p12_have_had_preeclampsia": false,
                "p12_have_had_eclampsia": false,
                "p12_have_had_an_embarazo_ectopico_etc": false
            }

            policyIssuanceClass.value.beneficiaries = issuanceData['beneficiaries-list'].map(beneficiary => {
                let birthdate = beneficiary.birthdate_beneficiaries;
                birthdate = birthdate.split('/');
                birthdate = `${birthdate[2]}-${birthdate[1]}-${birthdate[0]}`;

                return { 
                    "name": beneficiary.name_beneficiaries,
                    "middle_name": "",
                    "paternal_name": beneficiary.paternal_name_beneficiaries,
                    "maternal_name": beneficiary.maternal_name_beneficiaries,
                    "relationship": beneficiary.relationship_beneficiaries.replace('(a)',''),
                    "percentage": beneficiary.percentage_beneficiaries,
                    "date_of_birth": birthdate,
                    "adress": ""
                }
            });

            documentListIne.value = documentsIne;
            let documentListIneNew = documentListIne.value.map(doc => doc.url);
            documentListIneNew = documentListIneNew[0]+", "+documentListIneNew[1];

            documentListProofOfAddress.value = documentProofOfAddress;
            let documentListProofOfAddressNew = documentListProofOfAddress.value.map(doc => doc.url);
            documentListProofOfAddressNew = documentListProofOfAddressNew[0];
       
            policyIssuanceClass.value.documents = {
                "identificacion_oficial": true,
                "identificacion_oficial_url": documentListIneNew,
                "comprobante_domicilio": true,
                "comprobante_domicilio_url": documentListProofOfAddressNew,
                "calidad_migratoria": false,
                "calidad_migratoria_url": "",
                "comprobante_incripcion": false,
                "comprobante_incripcion_url": "",
                "curp": false,
                "curp_url": "",
                "rfc": false,
                "rfc_url": ""
            }

            policyIssuanceClass.value.agentDetails = {
                "info1": {
                    "name": "daniel",
                    "office": "oficina",
                    "key_code": 39423,
                    "participation": "2kdmmd",
                    "promotoria": "dñdñdñ"
                },
                "observations": "",
                "p1_date_of_knowing_the_applicant": "1900-01-01",
                "p2_identity_verification_type": "Presencial",
                "p3_completed_client_request": false,
                "p3_negative_answer": "",
                "p4_recommended_client": false,
                "p4_negative_answer": ""
            }

            let coverages = quoteData.coverages.added_benefit.map(coverage => coverage.option);
            coverages.unshift('basic');
            //TODO agregar condiciones de solicitante o contratante
            policyIssuanceClass.value.quoteInformation = {
                "age": getYears(personalInfo.birthday),
                "gender": (additionalApplicant == 'no')? (personalInfo.gender.toLowerCase().replace('(a)', '') == 'hombre')? 'male': 'female' : (quoteData.contracting_data.gender_contracting_data == 'hombre')? 'male': 'female',
                "is_smoker": (smoke.smoke_tobacco == 'no')? false: true,
                "assured_amount": quoteData.coverages.amount_insured,
                "coverages": coverages,
                "diseases": quoteData.diseases.diseases.concat(quoteData.diseases.other_diseases),
                "occupations": (additionalApplicant == 'no')? [userInfoQuote.simple_life_info.work_info.occupation] : quoteData.profession_occupation.occupation,
                "sports": quoteData.sports.sports,
                "activities": quoteData.hobbies.hobbies,//["actividad_prueba_basic1"]
                "weight": (additionalApplicant == 'no')? parseInt(quoteData.personal_info.weight) : parseInt(quoteData.contracting_data.weight),
                "height": (additionalApplicant == 'no')? quoteData.personal_info.height.toString().replaceAll('.', '') : quoteData.contracting_data.height.toString().replaceAll('.', '')
            }   

        }

        const getUserInformation = async (token, userId) => {
            let userInfo = quoteClass.value.getUserInformation(token, userId).then(resp => resp.data.response).catch(err => {
                if(err.response.status != 500) {
                    console.log("!Error 422:", err.response.data.response.message)
                    Swal.fire({
                        title: "Aviso",
                        html: err.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Ha ocurrido un error al obtener la información su cuenta.",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }
                return null;
            });

            if(userInfo.has_errors){
                Swal.fire({
                    title: "Aviso",
                    html: userInfo.message,
                    icon: "info",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
            }

            return userInfo;
        }

        const formatDate = (date) => {
            if(date == ''){
                return '';
            }

            let newDate = date.split('/')
            return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
        }

        const createStorage = (name, value) => {
            localStorage.setItem(name, JSON.stringify(value));
        }
        
        const getStorage = (name) => {
            return JSON.parse(localStorage.getItem(name));
        }

        const getSessionStorage = (name) => {
            return sessionStorage.getItem(name);
        }

        const storageExist = (name) => {
            return (localStorage.getItem(name))? true: false;
        }

        function isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        }

        const changeGoToBack = () => {
            counterGoBack.value = counterGoBack.value+1;
        }

        const next = () => {
            router.push({path:'/cuenta/emision/vida-simple/finalizar'})
        }

        const getTokenQuotation = async () => {
            token.value = await quoteClass.value.getToken().then(resp => resp.data.response.token_auth ).catch(err => {
                if(err.response.status != 500) {
                    console.log("!Error 422:", err.response.data.response.message)
                    Swal.fire({
                        title: "Aviso",
                        html: err.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Ha ocurrido un error con el servicio de cotización",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }
                return null;
            });
        }

        const generateQuotation = async(values) => {
            return await quoteClass.value.post(token.value, values).then(resp => resp.data.response ).catch(err => {
                
                if(err.response.status == 422) {
                    console.log("!Error 422:", err.response.data.response.message)
                    Swal.fire({
                        title: "Aviso",
                        html: err.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Ha ocurrido un error con el servicio de cotización",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }
                return null;
            });
        }

        const confirm = async () => {
            isLoading.value = true;
            const res = await post(process.env.VUE_APP_GSAPI+'/vs/application/create', policyIssuanceClass.value, { headers: {"Authorization" : `Token ${tokenAuthorization.value}`} }).then((response) => response).catch(err => {
                if(err.response.status != 500) {
                    Swal.fire({
                        title: "Aviso",
                        html: err.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Servicio de cotización no disponible, por favor inténtelo de nuevo más tarde",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }

                return null;
            });

            if(res){
                /* createStorage('issuance-finish', result); */
                removeItemsStorage();
                next();
            } 

            isLoading.value = false;
        }

        function validateProperty(property, responseType){
            if(property === undefined || property === ""){
                return responseType;
            }

            return property;
        }

        function removeItemsStorage(){
            const storaceList = ["document_proof_of_address", "issuance", "quote", "quote-data", "issuance-applicant-address",  "issuance-data", "quote-detail", "document_ine"];

            storaceList.forEach(key => {
                localStorage.removeItem(localStorage.key(key));
            })
        }

        return {
            category,
            changeGoToBack,
            counterGoBack,
            continueQuote,
            next,
            detail,
            confirm,
            isLoading,
            policyIssuanceClass,
            getYears,
            documentListIne,
            documentListProofOfAddress,
            contractingLocation,
            companyName,
            occupation,
            annualIncome
        }
    },
    components:{
        MenuPrincipal,
        HeaderMobil,
        MenuLateralMobile,
        MenuLateral,
        Footer,
        Loading
    }
}
</script>

<style scoped>
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}
</style>